<script>
export default {
    name: "methodsProposal",
    methods: {
        getNumberProposal(iten) {
            let block = '';
            if (iten.properties?.block) {
                block = iten.properties.block.replace(/^0+/, '');
            }
            let lot = '';
            if (iten.properties?.name) {
                lot = iten.properties.name.replace(/^0+/, '');
            }
            if (iten.created_at) {
                let date = iten.created_at?.split('T');
                date = date[1]?.split(':');
                let sec = date[2]?.split('.')
                return `${block}${lot}${date[0]}${date[1]}${sec[0]}`;
            }
            return `${block}${lot}`;
        },
        getNameClient(iten) {
            let name = null;
            iten.clients.forEach((item) => {
                if (!item.clients?.name) return;
                name = name !== null ? item.clients.name + ' ,' + name : item.clients.name;
            });
            if (!name) return 'Nenhum cliente vinculado';
            return name;
        },
        endDateFormat(value, created = false) {
            if (value && !created) {
                let dateAndTime = value.split(' ');
                let date = dateAndTime[0].split('-');
                let time = dateAndTime[1].split(':');
                return `${date[2]}/${date[1]}/${date[0]} às ${time[0]}:${time[1]}`;
            } else if (value && created) {
                let dateAndTime = value.split('T');
                let date = dateAndTime[0].split('-');
                let time = dateAndTime[1].split(':');
                return `${date[2]}/${date[1]}/${date[0]} às ${time[0]}:${time[1]}`;
            }
            return 'Indefinido'
        },
    }
}
</script>

<style scoped>

</style>