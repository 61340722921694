<template>
    <div>
        <div id="business_proposal" class="modal-body">
            <div style="overflow-x: hidden; min-height: 40vh">
                <div class="d-flex flex-row justify-content-end mb-2 mt-1">
                    <button class="btn btn-primary" @click="setModalFilter">Filtrar</button>
                </div>
                <div class="card" v-if="proposals?.data?.length">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-hover table-bordered"
                                   style="overflow: hidden">
                                <thead>
                                <tr>
                                    <th class="text-center">N°</th>
                                    <th class="text-center">{{ this.t('PROPOSAL.CLIENT') }}</th>
                                    <th class="text-center">{{ this.t('PROPOSAL.STATUS') }}</th>
                                    <th class="text-center">{{ this.t('PROPOSAL.REALTOR') }}</th>
                                    <th class="text-center">Q.</th>
                                    <th class="text-center">L.</th>
                                    <th class="text-center">Assinatura</th>
                                    <th class="text-center">{{ this.t('PROPOSAL.DATE_CREATE') }}</th>
                                    <th class="text-center">Ação</th>
                                </tr>
                                </thead>
                                <tr v-for="(iten, key) in proposals?.data" :key="key">
                                    <td class="text-center pt-1 pb-1">{{ getNumberProposal(iten) }}</td>
                                    <td class="text-center pt-1 pb-1">{{ getNameClient(iten) }}</td>
                                    <td class="text-center pt-1 pb-1">
                                        <div class="font-weight-bold d-flex justify-content-center w-100"
                                             style="width: 130px;" id="status">
                                            <div class="rounded-pill pb-1 pt-1 card m-0 pl-3 pr-3"
                                                 style="min-width: 130px"
                                                 :style="{'background-color': translateColorStatus(iten.status) ,'color': 'white'}">
                                                {{ translateStatus(iten.status) }}
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center pt-1 pb-1"> {{
                                            iten.user_name ? iten.user_name : 'Indefinido'
                                        }}
                                    </td>
                                    <td class="text-center pt-1 pb-1">{{ iten.properties?.block }}</td>
                                    <td class="text-center pt-1 pb-1">{{ iten.properties?.name }}</td>
                                    <td class="text-center pt-1 pb-1">
                                        <div v-if="iten?.click_sign_documents?.status"
                                             :style="{'background-color': translateColorStatusAssign(iten?.click_sign_documents?.status), 'margin-bottom': '0'}"
                                             class=" card text-white font-weight-bold p-1"
                                             style="font-size: 12px">
                                            {{ getStatusClickSing(iten?.click_sign_documents?.status) }}
                                        </div>
                                        <div v-else style="font-size: 12px"
                                        >{{ getStatusClickSing(iten?.click_sign_documents?.status) }}
                                        </div>
                                    </td>
                                    <td class="text-center pt-1 pb-1"> {{ endDateFormat(iten.created_at, true) }}</td>
                                    <td class="pt-1 pb-1">
                                        <div class="d-flex justify-content-center">
                                            <button class="btn btn-primary d-flex flex-row"
                                                    @click="generateContract(iten)"><i
                                                    class="icon-download mr-1 align-self-center"></i>Gerar
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card" v-if="!proposals?.data?.length">
                    <div class="card-header"></div>
                    <div class="card-body pt-0">
                        <div class="text-center">
                            <h5>Nenhuma proposta gerada neste loteamento</h5>
                        </div>
                    </div>
                </div>
                <pagination-component
                        v-if="proposals && proposals.data && proposals.data[0]"
                        :items="proposals"
                        :to="'/contratos'"
                        @changePage="getProposals($event)"></pagination-component>
            </div>
        </div>
        <transition name="fade">
            <div class="modal-mask" v-if="modalFilter">
                <div class="modal-wrapper" data-backdrop="static" data-keyboard="false">
                    <div class="modal-dialog modal-xl" role="document" style="margin-top: 0rem;">
                        <div class="modal-content">
                            <div class="modal-header modal-header-color">
                                <h4 class="modal-title">Filtro</h4>
                                <button type="button" class="closeX" @click="modalFilter = false">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row gutters">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label> Loteamento</label>
                                            <Select2 :settings="{width: '100%', placeholder: 'Selecione um Loteamento'}"
                                                     :options="allotments"
                                                     disabled
                                                     v-model="idAllotment"/>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>{{ t('RESERVE.REALTOR') }}: </label>
                                            <Select2 :settings="{ width: '100%', placeholder: 'Selecione um Corretor'}"
                                                     :options="realtors"
                                                     v-model="filter.realtorId"/>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>{{ t('RESERVE.CLIENT') }}: </label>
                                            <Select2 :settings="ajaxPerson" placeholder="Selecione um Cliente"
                                                     @select="selectClient"
                                                     v-model="filter.clientId"/>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                        <div class="form-group">
                                            <label>{{ t('PROPOSAL.STATUS_ASSIGN') }}: </label>
                                            <Select2 :settings="{width: '100%'}" placeholder="Selecione"
                                                     :options="[
                                                        {id:'Nenhum', text: 'Selecione'},
                                                        {id:'1', text: 'Aguardando'},
                                                        {id:'2', text: 'Assinado'},
                                                        {id:'6', text: 'Assinatura Manual Anexada'},
                                                        {id:'7', text: 'Assinatura Manual Cancelada'},
                                                        {id:'4', text: 'Cancelado'},
                                                        {id:'5', text: 'Cancelamento Solicitado'},
                                                        {id:'3', text: 'Expirado'},
                                                        {id:'none', text: 'Sem Assinatura'},
                                                     ]"
                                                     @select="selectStatusAssign"
                                                     v-model="filter.status_assing"/>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                        <div class="form-group">
                                            <label>{{ t('PROPERTIES.BLOCK') }}: </label>
                                            <Select2 :settings="{width: '100%'}" :options="blocks"
                                                     id="blockSelect"
                                                     placeholder="Selecione uma Quadra"
                                                     v-model="filter.block" @select="searchBlock($event)"/>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                        <div class="form-group">
                                            <label>{{ t('PROPERTIES.NAME') }}: </label>
                                            <Select2 :settings="{ width: '100%', placeholder: 'Selecione um lote'}"
                                                     :options="lots"
                                                     v-model="filter.lots" @select="searchLots($event)"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer justify-content-end">
                                <button class="btn btn-secondary mr-2" type="button" @click="modalFilter = false">
                                    {{ t("ACTIONS.CLOSE") }}
                                </button>
                                <button class="btn btn-secondary mr-2" type="button" @click="clearFilter">
                                    Limpar
                                </button>
                                <button class="btn btn-primary " type="button"
                                        @click="modalFilter = false; getProposals()">
                                    Filtrar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';
import Proposal from '../../services/Proposal';
import StatusProposal from '../../mixins/StatusProposal';
import methodsProposal from '../../mixins/methodsProposal';
import PaginationComponent from '../../components/layouts/PaginationComponent';
import Select2 from 'vue3-select2-component';
import {mapState} from 'vuex';
import Lots from '../../services/Lots';
import User from '@/services/user';

export default {
    name: "listProposalsForCreate",
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    computed: {
        ...mapState({
            allotments: 'allotments',
        }),
    },
    components: {
        PaginationComponent,
        Select2,
    },
    mixins: [StatusProposal, methodsProposal],
    props: ['allotmentId'],
    data() {
        return {
            realtors: [],
            blocks: [],
            lots: [],
            filter: {},
            proposals: {},
            modalFilter: false,
            idAllotment: null,
            ajaxPerson: {
                width: '100%',
                language: {
                    inputTooShort: function () {
                        return "Por favor, insira ao menos 1 caracteres para pesquisar.";
                    },
                    noResults: function () {
                        return "Nenhum resultado encontrado!";
                    }
                },
                minimumInputLength: 1,
                ajax: {
                    url: `${process.env.VUE_APP_BACK_END}/api/v1/clientes/list`,
                    dataType: "json",
                    headers: {
                        'authorization': `Bearer ${localStorage.getItem('access_token')}`,
                        'company-id': localStorage.getItem('companyId'),
                    },
                    data: params => {
                        return {
                            term: params.term,
                        };
                    },
                    processResults: function (data) {
                        if (!(data.validations)) {
                            data = data.map((i) => ({id: i.id, text: i.name}));
                            data.unshift({id: 0, text: "Nenhum"});
                            return {
                                results: data
                            };
                        } else {
                            return {results: {id: 0, text: "Nenhum"}};
                        }
                    },
                    cache: true
                },
                initSelection: function (element, callback) {
                    callback({id: 1, text: 'initSelection test'});
                },
            },
        }
    },
    mounted() {
        this.getProposals();
        this.idAllotment = this.allotmentId;
        this.indexRealtor();
    },
    watch: {
        allotmentId() {
            this.idAllotment = this.allotmentId
        }
    },
    methods: {
        selectClient({id, text}) {
            if (text === 'Nenhum') {
                this.filter.clientId = null;
                this.filter.clientName = null;
            } else {
                this.filter.clientId = id;
                this.filter.clientName = text;
            }
        },
        clearFilter(){
            this.$store.commit('changeLoading', true)
            this.modalFilter = false;
            this.filter = {};
            this.getProposals();
        },
        selectStatusAssign({id, text}) {
            if (id === 'Nenhum') {
                this.filter.status_assing = null;
                this.filter.status_assing_name = null;
            } else {
                this.filter.status_assing = id;
                this.filter.status_assing_name = text;
            }
        },
        async indexRealtor() {
            await User.listRealtor(this.allotmentId).then(resp => {
                if (!resp.data.validations) {
                    this.realtors = [];
                    this.realtors = resp.data.filter(item => {
                        if (typeof item === 'object' && item !== null) {
                            return {
                                id: item.id,
                                text: item.text,
                            }
                        }
                    });
                    this.realtors.unshift({id: 0, text: this.t('GENERAL.NONE')});
                } else this.realtors = [{id: 0, text: 'Nenhum'}];
            }).catch(error => {
                this.errorMsg(error);
            });
        },
        async indexBlocks() {
            await Lots.blocksList(this.allotmentId).then(async resp => {
                if (!resp.data?.validations) {
                    resp.data = resp.data.map(i => {
                        return {
                            id: i.text,
                            text: i.text,
                        }
                    })
                    this.blocks = [{id: 0, text: 'Nenhum'}, ...resp.data];
                }
            }).catch(error => {
                this.errorMsg(error);
            });
        },
        async getLots() {
            await Lots.getLotsByAllotment(this.allotmentId).then(resp => {
                this.lots = [{id: 0, text: 'Nenhum'}, ...resp.data];
            })
        },
        setModalFilter() {
            this.$store.commit('changeLoading', true);
            this.modalFilter = true;
            Promise.all([
                this.getLots(),
                this.indexBlocks(),
            ]).then(() => this.$store.commit('changeLoading', false)).catch(() => this.$store.commit('changeLoading', false))
        },
        searchLots({text}) {
            if (text == 'Nenhum') {
                delete this.filter.lots;
            }
        },
        searchBlock({text}) {
            if (text == 'Nenhum') {
                delete this.filter.block;
            }
        },
        closeModal() {
            document.getElementById('closeX')?.click();
        },
        getProposals(page = 1) {
            this.$store.commit('changeLoading', true);
            Proposal.listProposalsForCreateContract({page: page, allotmentId: this.allotmentId, filter: this.filter})
                    .then(resp => {
                        this.proposals = resp.data;
                    }).finally(() => this.$store.commit('changeLoading', false));
        },
        async generateContract(item) {
            this.$store.commit('changeLoading', true);
            await Proposal.createContract(item.id).then(res => {
                this.toast.success(this.t('PROPOSAL.CONTRACT_GENERATED'));
                const byteCharacters = atob(res.data.split(",")[1]);
                const byteNumbers = new Uint8Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const blob = new Blob([byteNumbers], {type: "application/pdf"});
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = "contrato.pdf";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.getProposals();
            }).catch(err => {
                this.$store.commit('changeLoading', false)
                this.errorMsg(err)
            });
        },
    }
}
</script>

<style scoped>

</style>