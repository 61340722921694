<template>
    <div>
        <load-screen-component></load-screen-component>
        <page-header pageTitle="CONTRACT.INDEX" filter @filter="setModalFilter" :btn-new="!isRealtor() && allotmentId ? 'Novo': null"
                     @newType="setRegisterModal"></page-header>
        <div class="card mb-1" v-if="hastwoAllotments">
            <div class="card-body pt-2 row">
                <div class="col-10 col-xl-3 col-md-3">
                    <label>{{ t('NAV.ALLOTMENT') }}</label>
                    <Select2 :settings="{ width: '100%', placeholder:  t('LOTS.ALLOTMENT')}" :options="allotments"
                             v-model="allotmentId" @select="changeAllotment($event)"/>
                </div>
            </div>
        </div>
        <div class="card" v-if="!data?.data?.length">
            <div class="card-header"></div>
            <div class="card-body pt-0">
                <div class="text-center">
                    <h5>{{ t('CONTRACT.NONE_FOUND') }}</h5>
                </div>
            </div>
        </div>
        <div class="card" v-if="data?.data?.length ">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-hover table-bordered" style="overflow: hidden;">
                        <thead>
                        <tr>
                            <th class="text-center">{{ this.t('CONTRACT.PROPOSAL_N') }}</th>
                            <th class="text-center">{{ t('CONTRACT.STATUS_PROPOSAL') }}</th>
                            <th class="text-center">{{ this.t('PROPOSAL.CLIENT') }}</th>
                            <th class="text-center">{{ this.t('PROPOSAL.REALTOR') }}</th>
                            <th class="text-center">Q.</th>
                            <th class="text-center">L.</th>
                            <th class="text-center">Assinatura</th>
                            <th class="text-center">{{ this.t('PROPOSAL.DATE_CREATE') }}</th>
                            <th class="text-center">
                                <div class="d-flex flex-row">
                                    <p>Ações</p>

                                </div>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(iten) in data.data" :key="iten.id">
                            <td class="text-center pt-2 pb-2">{{ getNumberProposal(iten) }}</td>
                            <td class="text-center pt-2 pb-2">
                                <div class="font-weight-bold d-flex justify-content-center w-100"
                                     style="width: 130px;" id="status">
                                    <div class="rounded-pill pb-1 pt-1 card m-0 pl-3 pr-3"
                                         style="min-width: 130px"
                                         :style="{'background-color': translateColorStatus(iten.status) ,'color': 'white'}">
                                        {{ translateStatus(iten.status) }}
                                    </div>
                                </div>
                            </td>
                            <td class="text-center pt-2 pb-2">{{ getNameClient(iten) }}</td>
                            <td class="text-center pt-2 pb-2">{{ iten.user_name ? iten.user_name : 'Indefinido' }}</td>
                            <td class="text-center pt-2 pb-2">{{ iten.properties?.block }}</td>
                            <td class="text-center pt-2 pb-2">{{ iten.properties?.name }}</td>
                            <td class="text-center pt-2 pb-2">
                                <div v-if="iten?.click_sign_documents_contract?.status"
                                     :style="{'background-color': translateColorStatusAssign(iten?.click_sign_documents_contract?.status), 'margin-bottom': '0'}"
                                     class="card text-white font-weight-bold p-1" style="font-size: 12px">
                                    {{ getStatusClickSing(iten?.click_sign_documents_contract?.status) }}
                                </div>
                                <div v-else style="font-size: 12px"
                                >{{ getStatusClickSing(iten?.click_sign_documents_contract?.status) }}
                                </div>
                            </td>
                            <td class="text-center pt-2 pb-2">{{ endDateFormat(iten.contract_date, false) }}</td>
                            <td class="text-center pt-2 pb-2">
                                <div>
                                    <a type="button" data-toggle="dropdown" aria-haspopup="true"
                                       aria-expanded="false" class="w-50"
                                       style="font-size: 20px" id="listDropdown">
                                        <i class="icon-more-horizontal" title="Opções"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-black w-auto">
                                        <div class="dropdown-item pointer text-white"
                                             data-toggle="modal" data-target="#customModalTwo"
                                             v-if="(hasConfig && iten.click_sign_documents_contract?.clicksign_id)"
                                             @click="viewDocumentClickSing(iten?.click_sign_documents_contract?.clicksign_id)">
                                            <i class="icon-eye mr-2 font-15"/>{{ 'Assinaturas' }}
                                        </div>
                                        <div class="dropdown-item pointer text-white"
                                             v-if="(!isRealtor() && hasConfig && iten.click_sign_documents_contract?.status === 1 && iten.status === 'APROVADA')"
                                             @click="cancelClickSing(iten.id)">
                                            <i class="icon-x-circle mr-2 font-15"/>{{ t('PROPOSAL.CANCEL_CLICKSIGN') }}
                                        </div>
                                        <div class="dropdown-item pointer text-white"
                                             data-toggle="modal" data-target="#customModalTwo"
                                             v-if="!isRealtor()"
                                             @click="setModalEdit(iten.id)">
                                            <i class="icon-pencil mr-2 font-15"/>{{ t('PROPOSAL.EDIT') }}
                                        </div>
                                        <div class="dropdown-item pointer text-white"
                                             data-toggle="modal" data-target="#customModalTwo"
                                             @click="setModalClientAnexView(iten)">
                                            <i class="icon-people mr-2 font-15"/>{{ t('PROPOSAL.CLIENT_FICH') }}
                                        </div>
                                        <div class="dropdown-item pointer text-white"
                                             v-if="!isRealtor() && (!iten.click_sign_documents_contract?.clicksign_id || iten.click_sign_documents_contract?.status === 3 || iten.click_sign_documents_contract?.status === 4)"
                                             @click="deleteContract(iten.id)">
                                            <i class="icon-trash mr-2 font-15"/>{{ t('ACTIONS.DELETE') }}
                                        </div>
                                        <div class="dropdown-item pointer text-white"
                                             v-if="!isRealtor() && (hasConfig && canSocityClickSign(iten?.click_sign_documents_contract?.status) && iten.status === 'APROVADA')"
                                             @click="solicitClickSing(iten.id)">
                                            <i class="icon-border_color mr-2 font-15"/>{{
                                                t('PROPOSAL.SOLICIT_CLICKSIGN')
                                            }}
                                        </div>
                                        <div class="dropdown-item pointer text-white"
                                             @click="reprintContract(iten)">
                                            <i class="icon-eye1 mr-2 font-15"/>{{ t('CONTRACT.VIEW') }}
                                        </div>
                                        <div class="dropdown-item pointer text-white"
                                             @click="reprintProposal(iten)">
                                            <i class="icon-eye1 mr-2 font-15"/>{{ t('PROPOSAL.VIEW') }}
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row gutters">
            <div class="col-3" v-if="!widthMd(1000)"></div>
            <div :class="!widthMd(1000) ? 'col-6' : 'col-12'">
                <pagination-component v-if="data?.data?.length" :items="data" :to="'/contratos'"
                                      @changePage="index(filter, $event)"></pagination-component>
            </div>
            <div class="col-3" v-if="!widthMd(1000)">
                <div class="row gutters d-flex justify-content-end" v-if="data && data.data && data.data[0]">
                    <div class="col-8">
                        <label>Contratos por página: </label>
                        <Select2 :settings="{width: '100%'}" placeholder="Selecione" @select="changePaginate"
                                 :options="paginate"
                                 id="paginate" name="paginate"
                                 v-model="perPage"
                        ></Select2>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <modal-select-allotment v-if="allotments" :showModal="showAllotmentSelect" :is-contract="true"
                                    @showModal="showAllotmentSelect = $event" @allotmentSelected="allotmentSelected">
            </modal-select-allotment>
        </div>
        <modal :modal-xl="true" :title-modal="modalTitle" v-if="modalChoose !== 6">
            <template v-slot:title>{{ t(modalTitle) }}</template>
            <edit-contract-component v-if="modalChoose === 1" :proposal-index="proposal"></edit-contract-component>
            <view-document-click-sign-component v-if="modalChoose === 2"
                                                :itens-index="documentClickSing"></view-document-click-sign-component>
            <filter-contract-component v-if="modalChoose === 3" :perPageIndex="perPage"
                                       @search="filterProposal" @close="closeFilter()"></filter-contract-component>
            <listPersonDownloadFich v-if="modalChoose === 5" :clients="clientsForFich"></listPersonDownloadFich>

        </modal>
        <modal :modal-extra-large="true" :title-modal="modalTitle" v-if="modalChoose === 6" @close="closeModalCreate">
            <template v-slot:title>{{ t(modalTitle) }}</template>
            <list-proposals-for-create v-if="modalChoose === 6" :allotment-id="allotmentId"></list-proposals-for-create>
        </modal>
    </div>
</template>

<script>
import loadScreenComponent from '../../components/layouts/loadScreenComponent';
import pageHeader from '../../components/layouts/pageHeader';
import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';
import Proposal from '../../services/Proposal';
import {mapState} from 'vuex';
import Select2 from 'vue3-select2-component';
import modalSelectAllotment from '../allotment/modalSelectAllotmentComponent';
import PaginationComponent from '../../components/layouts/PaginationComponent';
import modal from '../../components/modal';
import FilterContractComponent from './FilterContractComponent';
import isReator from '../../mixins/isReator';
import editContractComponent from './editContractComponent';
import ConfigClickSign from '../../services/ConfigClickSign';
import ViewDocumentClickSignComponent from '../proposal/ViewDocumentClickSignComponent';
import {PDFDocument} from 'pdf-lib';
import Clients from '@/services/Clients';
import listPersonDownloadFich from '@/pages/proposal/listPersonDownloadFich';
import widthMd from '../../mixins/widthMd';
import StatusProposal from '../../mixins/StatusProposal';
import listProposalsForCreate from './listProposalsForCreate';

export default {
    name: "indexContractComponent",
    components: {
        loadScreenComponent,
        pageHeader,
        modalSelectAllotment,
        Select2,
        PaginationComponent,
        modal,
        FilterContractComponent,
        editContractComponent,
        ViewDocumentClickSignComponent,
        listPersonDownloadFich,
        listProposalsForCreate,
    },
    mixins: [isReator, widthMd, StatusProposal],
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    data() {
        return {
            allotmentId: null,
            proposal: {},
            hasConfig: false,
            showAllotmentSelect: false,
            data: {},
            documentClickSing: [],
            filter: null,
            perPage: null,
            modalTitle: null,
            modalChoose: null,
            clientsForFich: [],
        }
    },
    mounted() {
        this.allotmentId = localStorage.getItem('contractAllotmentId');
        this.showAllotmentSelect = this.hastwoAllotments == true && (localStorage.getItem('contractAllotmentId') ? false : true);
        if (this.allotments?.length === 1 || this.allotmentId) {
            this.allotmentId = this.allotmentId ? this.allotmentId : this.allotments[0].id;
            localStorage.setItem('contractAllotmentId', this.allotmentId);
            this.index();
        }
        this.checkHasConfig();
    },
    computed: {
        ...mapState(['isLoading', 'hastwoAllotments', 'allotments', 'paginate'])
    },
    methods: {
        closeModalCreate()
        {
            this.modalChoose = null;
            this.modalTitle = null;
            this.index()
        },
        setRegisterModal(){
            this.$store.commit('changeLoading', true);
            this.modalChoose = 6;
            this.modalTitle = 'Propostas para gerar contrato:';
        },
        changePaginate() {
            this.index(this.filter);
        },
        async setModalClientAnexView(iten) {
            if (iten.clients.length === 1) {
                await this.printClientPdf(iten.clients?.find(i => i).clients);
                document.getElementById('closeX')?.click();
            } else {
                this.modalChoose = 5;
                this.modalTitle = 'Download ficha do cliente';
                this.clientsForFich = iten.clients;
            }
        },
        async printClientPdf(iten) {
            this.$store.commit('changeLoading', true);
            await Clients.exportPdfClient(iten.id).then(async (response) => {
                if (response.data?.length) {
                    const mergedPdf = await PDFDocument.create();
                    for (const pdfObj of response.data) {
                        if (pdfObj?.basefile) {
                            const pdfBase64 = pdfObj.basefile;
                            const pdfBytes = this.base64ToUint8Array(pdfBase64);
                            try {
                                const pdfDoc = await PDFDocument.load(pdfBytes);
                                const copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
                                copiedPages.forEach((page) => mergedPdf.addPage(page));
                            } catch (err) {
                                const blob = new Blob([pdfBytes], {type: 'application/pdf'});
                                const url = URL.createObjectURL(blob);
                                const a = document.createElement('a');
                                a.href = url;
                                let fileName = response.data.find(i => i)?.name ? response.data.find(i => i)?.name : 'arquivoPessoas.pdf';
                                fileName = fileName?.replaceAll('.pdf', '');
                                fileName = fileName + (pdfObj?.name ? pdfObj?.name : '.pdf');
                                a.download = fileName;
                                document.body.appendChild(a);
                                a.click();
                                document.body.removeChild(a);
                                URL.revokeObjectURL(url);
                            }
                        }
                    }

                    const mergedPdfFile = await mergedPdf.save();
                    const blob = new Blob([mergedPdfFile], {type: 'application/pdf'});
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    let fileName = response.data.find(i => i)?.name ? response.data.find(i => i)?.name : 'arquivoPessoas.pdf'
                    a.href = url;
                    a.download = fileName;
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    URL.revokeObjectURL(url);
                }
                this.$store.commit('changeLoading', false);
            }).catch((err) => {
                this.$store.commit('changeLoading', false);
                this.errorMsg(err);
            })
        },
        base64ToUint8Array(base64) {
            const base64Data = base64.replace(/^data:application\/pdf;base64,/, '');
            const binaryString = window.atob(base64Data);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            return bytes;
        },
        deleteContract(id) {
            this.$swal.fire({
                title: `Realmente deseja deletar o contrato?`,
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: `Sim`,
                cancelButtonText: 'Não',
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.commit('changeLoading', true);
                    Proposal.deleteContract(id).then(() => {
                        this.index();
                        this.$store.commit('changeLoading', false);
                    }).catch((err) => {
                        this.$store.commit('changeLoading', false);
                        this.errorMsg(err);
                    });
                }
            });
        },
        viewDocumentClickSing(id) {
            this.$store.commit('changeLoading', true);
            Proposal.showDocumentClickSign(id).then((resp) => {
                this.modalChoose = 2;
                this.modalTitle = this.t('PROPOSAL.DOCUMENTS');
                resp.data.open = true;
                this.documentClickSing = [resp.data];
                this.$store.commit('changeLoading', false);
            }).catch((err) => {
                this.$store.commit('changeLoading', false);
                if (err.response?.data?.validations?.message) {
                    this.toast.error(err.response.data.validations.message);
                }
            });
        },
        canSocityClickSign(status) {
            if (!status) {
                return true;
            }
            switch (status) {
                case 3:
                case 4:
                case 5:
                case 7:
                    return true;
            }
            return false;
        },
        checkHasConfig() {
            ConfigClickSign.index().then(resp => {
                this.hasConfig = !!resp.data?.data?.length;
            }).catch(() => {
                this.hasConfig = false;
            })
        },
        reprintProposal(item) {
            this.$store.commit('changeLoading', true);
            Proposal.reprintProposal(item.id).then(resp => {
                this.$store.commit('changeLoading', false);
                let win = window.open();
                win.document.write('' +
                        '<iframe src="' + resp.data + '"' +
                        'frameborder="0" ' +
                        'style="width:100vw; height:100vh; margin: -0.6rem;"' +
                        'allow="fullscreen">' +
                        '</iframe>'
                );
            }).catch(() => {
                this.$store.commit('changeLoading', false)
            })
        },
        solicitClickSing(id) {
            this.$swal.fire({
                title: `Realmente deseja solicitar assinatura ?`,
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: `Sim`,
                cancelButtonText: 'Não',
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.commit('changeLoading', true);
                    Proposal.solicitClickSignContract(id).then(() => {
                        this.index();
                        this.toast.success('Assinatura de Documento requisitada com sucesso!');
                    }).catch((err) => {
                        this.$store.commit('changeLoading', false);
                        this.errorMsg(err)
                    })
                }
            });
        },
        cancelClickSing(id) {
            this.$swal.fire({
                title: `Realmente deseja cancelar assinatura ?`,
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: `Sim`,
                cancelButtonText: 'Não',
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.commit('changeLoading', true);
                    Proposal.cancelClickSignContract(id).then(() => {
                        this.index();
                        this.toast.success('Assinatura Cancelada com sucesso!');
                    }).catch((err) => {
                        this.$store.commit('changeLoading', false);
                        if (err.response?.data?.validations?.message) {
                            this.toast.error(err.response.data.validations.message);
                        }
                    })
                }
            });
        },
        reprintContract(item) {
            this.$store.commit('changeLoading', true);
            Proposal.reprintContract(item.id).then(resp => {
                this.$store.commit('changeLoading', false);
                let win = window.open();
                win.document.write('' +
                        '<iframe src="' + resp.data + '"' +
                        'frameborder="0" ' +
                        'style="width:100vw; height:100vh; margin: -0.6rem;"' +
                        'allow="fullscreen">' +
                        '</iframe>'
                );
            }).catch(() => {
                this.$store.commit('changeLoading', false)
            })
        },
        index(filter = null, page = 1, exportIn = null) {
            this.filter = filter;
            this.$store.commit('changeLoading', true);
            Proposal.indexProposalContract({
                filter: this.filter,
                allotmentId: this.allotmentId,
                page,
                perpage: this.perPage,
                exportIn
            }).then(r => {

                this.data = r.data;
                this.$store.commit('changeLoading', false);
            }).catch(() => {
                this.$store.commit('changeLoading', false);
            })
        },
        setModalEdit(id) {
            this.modalTitle = 'CONTRACT.EDIT';
            this.modalChoose = 1;
            this.$store.commit('changeLoading', true);
            Proposal.showContract(id).then((resp) => {
                this.proposal = resp.data;
                this.$store.commit('changeLoading', false);
            }).catch(() => {
                this.$store.commit('changeLoading', false);
            })
        },
        filterProposal(items, filter, allotmentId) {
            this.data = items.data;
            this.filter = filter;
            this.allotmentId = allotmentId;
        },
        closeFilter() {
            document.getElementById('closeX')?.click();
            this.filter = null;
            this.modalChoose = null;
            this.index();
        },
        getNumberProposal(iten) {
            let block = '';
            if (iten.properties?.block) {
                block = iten.properties.block.replace(/^0+/, '');
            }
            let lot = '';
            if (iten.properties?.name) {
                lot = iten.properties.name.replace(/^0+/, '');
            }
            if (iten.created_at) {
                let date = iten.created_at?.split('T');
                date = date[1]?.split(':');
                let sec = date[2]?.split('.')
                return `${block}${lot}${date[0]}${date[1]}${sec[0]}`;
            }
            return `${block}${lot}`;
        },
        getNameClient(iten) {
            let name = null;
            iten.clients.forEach((item) => {
                if (!item.clients?.name) return;
                name = name !== null ? item.clients.name + ' ,' + name : item.clients.name;
            });
            if (!name) return 'Nenhum cliente vinculado';
            return name;
        },
        markImpress(iten) {
            this.$swal.fire({
                title: `Realmente deseja marcar a proposta N° ${this.getNumberProposal(iten)}
                como ${iten.is_impress ? 'Não Impressa' : 'Impressa'} ?`,
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: `Sim`,
                cancelButtonText: 'Não',
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.commit('changeLoading', true);
                    Proposal.alterImpress(iten.id).then((resp) => {
                        if (!resp?.data?.validations) {
                            iten.is_impress = resp.data.is_impress;
                        }
                    }).catch(err => this.errorMsg(err)).finally(() => this.$store.commit('changeLoading', false));
                }
            });
        },
        endDateFormat(value, created = false) {
            if (value && !created) {
                let dateAndTime = value.split(' ');
                let date = dateAndTime[0].split('-');
                let time = dateAndTime[1].split(':');
                return `${date[2]}/${date[1]}/${date[0]} às ${time[0]}:${time[1]}`;
            } else if (value && created) {
                let dateAndTime = value.split('T');
                let date = dateAndTime[0].split('-');
                let time = dateAndTime[1].split(':');
                return `${date[2]}/${date[1]}/${date[0]} às ${time[0]}:${time[1]}`;
            }
            return 'Indefinido'
        },
        setModalFilter() {
            this.modalTitle = 'ACTIONS.FILTER';
            this.modalChoose = 3;
        },
        changeAllotment({id}) {
            this.modalChoose = null;
            localStorage.removeItem('contractAllotmentId');
            localStorage.setItem('contractAllotmentId', id);
            this.index();
        },
        allotmentSelected() {
            this.allotmentId = localStorage.getItem('contractAllotmentId');
            this.index();
        },
        setModalHoover(id) {
            document.getElementById(id).click();
        },
        toCurrency(value) {
            if (value === null) return 'Indisponível';
            return Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(value);
        },
    }
}
</script>

<style scoped>

</style>